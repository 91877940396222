/* Sidebar Menu (Initially Hidden) */
.sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    /* Start hidden */
    width: 250px;
    height: 100%;
    background-color: white;
    box-shadow: 2px 0px 5px rgba(0, 0, 0, 0.2);
    transition: left 0.3s ease-in-out;
    z-index: 1050;
    padding-top: 20px;
}

/* Show Sidebar */
.sidebar.show {
    left: 0;
}

/* Close Button */
.sidebar .close-btn {
    background-color: rgb(144, 19, 117);
    color: #fff;
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
    /* background: none; */
    border: none;
}

/* Sidebar Nav Links */
.sidebar-nav {
    list-style: none;
    padding: 20px;
}

.sidebar-nav li {
    margin-bottom: 15px;
}

.sidebar-nav a {
    text-decoration: none;
    color: #333;
    font-size: 18px;
    display: block;
    padding: 10px;
}

.sidebar-nav ul {
    padding-left: 15px;
}

/* Overlay (For Background Fade Effect) */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1040;
    display: none;
}

.overlay.show {
    display: block;
}

/* Hide Sidebar on Large Screens */
@media (min-width: 992px) {
    .sidebar {
        display: none;
    }
}

.social {
    list-style: none;
}

/* Hide Search Bar on Small Screens */
@media (max-width: 993px) {
    .headerSearch {
        display: none;
    }
}
@media (max-width: 993px) {
    .navbar-toggler  {
        margin-top: -130px;
        /* margin-left: auto; */
    }
    .logo{
        margin-left: auto;
    }
}
@media (max-width: 752px) {
    .navbar-toggler  {
        margin-top: -180px;
        /* margin-left: 180px; */
    }
}
@media (max-width: 435px) {
    .navbar-toggler  {
        margin-top: -140px;
        margin-left: 180px;
    }
}
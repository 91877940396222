.select{width: 180px; height: auto; background: #fff; position:absolute; top: 50px; left: -20px; z-index: 100;
    box-shadow:  0 3px 6px rgb(0 0 0 / 20%); padding: 15px;
}
.selectDrop{
    position: relative;
}
.selectDrop .openSelect{
    display: block;
    padding: 10px 0px;

}
/* .selectDrop .openSelect .arrow{
    position: absolute;
    top: 9px;
   
    left: 150px;
} */
/* .searchfield input{
width: 100%;
height: 40px;
border: 1px solid #bce3c9;
outline: none;
padding: 0px 15px;
} */
.select .searchResults{
width: 100%; margin: 0px; 
padding: 5px 0px;
max-height: 300px;
/* overflow-y: scroll; */
}
.select .searchResults ::-webkit-scrollbar{width: 10px !important; } 

.select .searchResults ::-webkit-scrollbar-thumb{background: #ccc !important;}
.select .searchResults li{
  list-style: none;  font-size: 14px;
width: 100%;
padding: 10px;
color: rgba(0,0,0,0.7);
}
.select .searchResults li:hover{
background: rgb(144, 19, 117); color:#fff ;
}
.select .searchResults li.active{
   background: #ccc; color: black;
}
#container{
    width:100%;
    margin: 50px auto;
    clear:both;
  }
  #container:before, #container:after{
    content: '';
    display: table;
    clear: both;
  }
  .right{
    /* width: 900px; */
    display: inline-block;
    vertical-align: top;
    float: right;
  }
  .right div{
    background-color: #f0f0f0;
    height: 250px;
  }
  /* .right div + div{
    margin-top: 50px;
    ;
  
  } */
  .left{
    width: 20%;
    height: 550px;
    background-color: #f0f0f0;
    display: inline-block;
    float: left;
    position: sticky;
    top: 40px;
  
  
  }
  #id{
    text-decoration: none;
 
  }
  .add:hover{
    transform: scale(1.05);
  transition: transform 0.3s ease;
  }

  /* Media query for smaller screens (max-width: 1000px) */
@media (max-width: 1000px) {
  /* Reset the layout so the right side appears above the left side */
  .right {
    width: 100%;  /* Take full width */
    float: none;  /* Remove float */
    display: block;
  }

  .left {
    width: 100%;  /* Take full width */
    height: auto; /* Adjust height */
    position: static; /* Remove sticky behavior */
    float: none;  /* Remove float */
    display: block;
    margin-top: 20px; /* Add some spacing from the right section */
  }
}